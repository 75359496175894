<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col">
        <v-text-field
          class="centered-input text--darken-3 mt-3"
          v-model="phone"
          :rules="[rules.phoneNumber]"
          label="Enter phone number"
        ></v-text-field>
      </div>
    </div>
    <div class="buttons">
      <button
        class="btn btn-default btn-circle"
        v-for="(num, i) in keypads"
        :key="i"
        @click="addKey(num)"
      >
        <strong>{{ num }}</strong>
      </button>
      <button class="btn btn-default btn-circle" @click="clearKey">
        &larr;
      </button>
      <button class="btn btn-default btn-circle" @click="addKey(0)">0</button>
      <button class="btn btn-primary btn-circle" @click="call()">
        <i class="fa fa-fw fa-phone"></i>
      </button>
    </div>
  </div>
</template>

<style scoped>
.buttons {
  display: flex;
  font-family: Helvetica;
  font-weight: 400;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  width: 260px;
}
.btn-circle {
  line-height: 60px;
  margin: 10px;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.42;
  border-radius: 30px;
  cursor: pointer;
}
.btn-circle:hover {
  border: 1px solid #12357e !important;
  color: #12357e;
}
.centered-input >>> input {
  text-align: center;
}
</style>

<script>
export default {
  name: "dialer",
  data() {
    return {
      keypads: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      phone: "+",
      rules: {
        phoneNumber: value => {
          return this.validatePhoneForE164(value) || "Invalid phone number";
        }
      }
    };
  },
  methods: {
    addKey(num) {
      this.phone += `${num}`;
    },
    clearKey() {
      if (this.phone) {
        this.phone = this.phone.slice(0, -1);
      }
    },
    call() {
      if (!this.validatePhoneForE164(this.phone)) {
        this.$store.dispatch("showSnackbar", {
          message: "Invalid phone number",
          color: "error"
        });
        return;
      }

      this.$emit("callRequested", this.phone);
    },
    validatePhoneForE164(phone) {
      const regEx = /^\+[1-9]\d{10,14}$/;

      return regEx.test(phone);
    }
  }
};
</script>
