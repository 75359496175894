<template>
  <div>
    <!-- begin:: Content Head -->
    <KTSubheader
      v-bind:breadcrumbs="breadcrumbs"
      v-bind:title="workerInfo.friendlyName"
    >
      <template v-slot:button-content>
        <span class="switch switch-icon">
          <label>
            <input
              type="checkbox"
              :checked="workerInfo.available"
              :disabled="buttons.toggleAvailability.disabled"
              @change="toggleWorkerAvailability()"
            />
            <span></span>
          </label>
        </span>
        <div class="form-text text-muted">
          {{ workerInfo.activityName }}
        </div>
      </template>
    </KTSubheader>
    <!-- end:: Content Head -->
    <div class="row">
      <div class="col-xxl-4">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Calls</span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0 pb-3">
            <div class="tab-content">
              <!--begin::Row-->
              <div class="row">
                <div class="col">
                  <Dialer
                    v-if="!connection"
                    v-on:callRequested="onCallRequested"
                  />
                  <div v-if="connection">
                    <div
                      class="d-block text-center"
                      v-if="
                        currentReservation && currentReservation.task.attributes
                      "
                    >
                      <h3
                        v-if="!currentReservation.task.attributes.targetWorker"
                      >
                        {{ currentReservation.task.attributes.from }}
                      </h3>
                      <h3
                        v-if="currentReservation.task.attributes.targetWorker"
                      >
                        {{ currentReservation.task.attributes.to }}
                      </h3>
                      <h3>
                        {{
                          currentReservation.task.attributes.selected_language
                            | language
                        }}
                      </h3>
                      <h3>{{ callInfo.minutes }}:{{ callInfo.seconds }}</h3>
                    </div>
                    <div class="d-block text-center" v-else>
                      <h3>Unknown</h3>
                    </div>
                    <div class="d-block text-center mt-20">
                      <button
                        class="btn btn-primary btn-circle mr-3"
                        :disabled="buttons.answerCall.disabled"
                        @click="answerCall()"
                      >
                        <i class="fa fa-fw fa-phone"></i>
                      </button>
                      <button
                        class="btn btn-danger btn-circle ml-3"
                        :disabled="buttons.hangUp.disabled"
                        @click="hangUp()"
                      >
                        <i class="fa fa-fw fa-phone-slash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Row-->
            </div>
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div class="col-xxl-4">
        <ReservationList
          v-bind:reservations="reservations"
          v-on:taskCompleted="onTaskCompleted"
          v-on:reservationAccepted="onReservationAccepted"
        ></ReservationList>
      </div>
      <div class="col-xxl-4">
        <CallStats ref="callStats" />
        <CallTimeStats ref="callTimeStats" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-xxl-12">
        <CallList></CallList>
      </div>
    </div>
  </div>
</template>
<style scoped>
.btn-circle {
  line-height: 60px;
  margin: 10px;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.42;
  border-radius: 30px;
  cursor: pointer;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import { mapGetters, mapState } from "vuex";
import { Device } from "twilio-client";
import {
  GET_TWILIO_TOKEN,
  GET_TWILIO_WORKER_TOKEN,
  UPDATE_TWILIO_RESERVATION,
  COMPLETE_TWILIO_TASK,
  CREATE_TWILIO_TASK,
  COMPLETE_TWILIO_CALL
} from "@/core/services/store/twilio.module.js";
import { SET_CONNECTION } from "@/core/services/store/call.module.js";
import ReservationList from "../../content/calls/ReservationList.vue";
import Dialer from "../../content/calls/Dialer.vue";
import CallList from "../../content/calls/list/CallList.vue";
import CallStats from "../../content/stats/CallStats.vue";
import CallTimeStats from "../../content/stats/CallTimeStats.vue";

export default {
  name: "dashboard",
  components: {
    KTSubheader,
    ReservationList,
    CallList,
    Dialer,
    CallStats,
    CallTimeStats
  },
  data: () => ({
    muted: false,
    connection: null,
    currentReservation: null,
    callInfo: { minutes: 0, seconds: 0 },
    timerInterval: null,
    device: null,
    worker: null,
    workerInfo: {
      activityName: "Offline",
      available: false,
      friendlyName: ""
    },
    workerActivities: {
      offline: "WA49d17269a641e285de043cef220d8b5d",
      available: "WA89cf279c806808d18a7c9b489f5575ef"
    },
    reservations: [],
    buttons: {
      answerCall: {
        disabled: true
      },
      hangUp: {
        disabled: true
      },
      toggleAvailability: {
        disabled: false
      }
    }
  }),
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Agent" }]);

    await this.registerTwilioDevice();
    await this.registerWorker();
  },
  computed: {
    ...mapState({
      twilioToken: state => state.twilio.token,
      twilioWorkerToken: state => state.twilio.workerToken
    }),
    ...mapGetters(["breadcrumbs"])
  },
  methods: {
    async registerTwilioDevice() {
      // Fetch Twilio capability token from backend
      await this.$store.dispatch(GET_TWILIO_TOKEN);

      // Setup device twilio token
      this.device = new Device(this.twilioToken, {
        codecPreferences: ["opus", "pcmu"],
        enableRingingState: true
        // sounds: { incoming: "/ring.mp3" }
      });

      this.device.on("ready", device => {});

      this.device.on("error", error => {
        // console.error(`Twilio.Device Error: ${error.message}`);
      });

      this.device.on("connect", connection => {
        this.setConnection(connection);

        this.buttons.answerCall.disabled = true;
        this.buttons.hangUp.disabled = false;
      });

      this.device.on("disconnect", connection => {
        this.setConnection(null);

        this.buttons.answerCall.disabled = true;
        this.buttons.hangUp.disabled = true;
      });

      this.device.on("incoming", connection => {
        this.setConnection(connection);

        this.callInfo = { minutes: 0, seconds: 0 };

        this.buttons.answerCall.disabled = false;
        this.buttons.hangUp.disabled = false;
      });
    },

    setConnection(connection) {
      this.connection = connection;
      this.$store.dispatch(SET_CONNECTION, connection);
    },

    async registerWorker() {
      // Fetch Twilio worker token from backend
      await this.$store.dispatch(GET_TWILIO_WORKER_TOKEN);
      // eslint-disable-next-line no-undef
      this.worker = new Twilio.TaskRouter.Worker(
        this.twilioWorkerToken,
        true,
        this.workerActivities.available,
        this.workerActivities.offline,
        true
      );

      this.toggleWorkerAvailability();

      this.worker.on("ready", workerInfo => {
        this.workerInfo = workerInfo;
        this.updateReservations();
      });

      this.worker.on("reservation.created", reservation => {
        this.updateReservations();

        if (!this.currentReservation) this.currentReservation = reservation;

        if (reservation.task.attributes.targetWorker) {
          this.onReservationAccepted(reservation);
        }
      });

      this.worker.on("reservation.accepted", reservation => {
        this.updateReservations();
        if (this.connection && reservation.task.attributes.targetWorker) {
          this.answerCall();
        }
      });

      this.worker.on("reservation.rejected", reservation => {
        this.updateReservations();
      });

      this.worker.on("reservation.canceled", reservation => {
        if (
          this.connection &&
          reservation.task.attributes.worker_call_sid ==
            this.connection.parameters.CallSid
        ) {
          this.hangUp();
        }
        this.updateReservations();
      });

      this.worker.on("reservation.rescinded", reservation => {
        this.updateReservations();
      });

      this.worker.on("reservation.completed", reservation => {
        this.updateReservations();
      });

      this.worker.on("reservation.wrapup", reservation => {
        this.updateReservations();
      });
    },

    async completeCall(callSid) {
      await this.$store.dispatch(COMPLETE_TWILIO_CALL, { callSid });
    },

    updateReservations() {
      this.worker.fetchReservations((error, reservations) => {
        this.reservations = reservations.data;
      });
    },

    onTaskCompleted(taskSid) {
      this.$store.dispatch(COMPLETE_TWILIO_TASK, { taskSid });
    },

    async onReservationAccepted(reservation) {
      await this.updateReservation(reservation, "accepted");
    },

    async updateReservation(reservation, status) {
      await this.$store.dispatch(UPDATE_TWILIO_RESERVATION, {
        reservationSid: reservation.sid,
        taskSid: reservation.task.sid,
        workspaceSid: reservation.workspaceSid,
        status: status
      });
    },

    onCallRequested(phone) {
      const targetWorker = this.workerInfo.sid;
      this.$store.dispatch(CREATE_TWILIO_TASK, { targetWorker, to: phone });
    },

    toggleWorkerAvailability() {
      this.buttons.toggleAvailability.disabled = true;

      const newStatus = {
        ActivitySid: this.workerInfo.available
          ? this.workerActivities.offline
          : this.workerActivities.available
      };
      this.worker.update(newStatus, (error, workerInfo) => {
        this.buttons.toggleAvailability.disabled = false;
        if (error) {
          this.$store.dispatch("showSnackbar", {
            message: error.message,
            color: "error"
          });
        }
        if (workerInfo) this.workerInfo = workerInfo;
      });
    },

    // Handle muting
    toggleMute() {
      this.muted = !this.muted;
      this.device.activeConnection().mute(this.muted);
    },

    async answerCall() {
      if (this.connection) {
        await this.connection.accept();

        this.clearTimerInterval();
        this.timerInterval = setInterval(() => {
          if (this.callInfo.seconds >= 59) {
            this.callInfo.seconds = 0;
            this.callInfo.minutes++;
          }

          this.callInfo.seconds++;
        }, 1000);
      }
    },

    async hangUp() {
      this.currentReservation = null;
      try {
        await this.completeCall(this.connection.parameters.CallSid);
      } catch (error) {
        // console.log(error);
      }
      if (this.device) {
        await this.device.disconnectAll();
      }
      this.setConnection(null);
      await new Promise(p => setTimeout(p, 1000));
      this.tryCompleteTasks();
      this.$refs.callStats.getStats();
      this.$refs.callTimeStats.getStats();
      this.clearTimerInterval();
    },

    clearTimerInterval() {
      try {
        clearInterval(this.timerInterval);
      } catch (error) {
        // console.log(error);
      }
    },

    tryCompleteTasks() {
      for (const reservation of this.reservations) {
        if (
          reservation.task.assignmentStatus == "wrapping" ||
          reservation.task.assignmentStatus == "assigned"
        ) {
          reservation.completeTaskDisabled = true;
          this.onTaskCompleted(reservation.task.sid);
        }
      }
    },

    async rejectReservations() {
      for (const reservation of this.reservations) {
        if (reservation.reservationStatus == "pending") {
          await reservation.reject();
        }
      }
    },

    // Handle numeric buttons
    sendDigit(digit) {
      this.connection.sendDigits(digit);
    }
  },
  async beforeDestroy() {
    try {
      await this.rejectReservations();
      if (this.workerInfo.available) this.toggleWorkerAvailability();
    } catch (error) {
      // console.log(error);
    }

    if (this.connection) {
      await this.hangUp();
    }
    this.clearTimerInterval();
  }
};
</script>
