<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Reservations</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template v-for="(reservation, i) in reservations">
        <!--begin::Item-->
        <div
          class="d-flex flex-wrap align-items-center"
          v-bind:key="i"
          v-bind:class="{ 'mb-9': lastElement(i) }"
        >
          <!--begin::Title-->
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
            <template v-if="reservation.task.attributes.caller">
              <h2>Caller: {{ reservation.task.attributes.caller }}</h2>
              <span class="text-muted font-weight-bold font-size-lg"
                >Language:
                <span class="text-primary font-weight-bold">{{
                  reservation.task.attributes.selected_language | language
                }}</span></span
              >
            </template>
            <template v-else>
              <h2>To: {{ reservation.task.attributes.to }}</h2>
            </template>
            <span class="text-muted font-weight-bold font-size-lg my-1">
              Reservation Status: {{ reservation.reservationStatus }}
            </span>
            <span class="text-muted font-weight-bold font-size-lg"
              >Task Status:
              <span class="text-primary font-weight-bold">{{
                reservation.task.assignmentStatus
              }}</span></span
            >
          </div>
          <!--end::Title-->
          <!--begin::Info-->
          <div class="d-flex align-items-center py-lg-0 py-2">
            <div class="d-flex flex-column text-right">
              <b-button
                variant="success"
                v-if="reservation.reservationStatus == 'pending'"
                :disabled="reservation.disableButtons"
                v-on:click="
                  reservation.disableButtons = true;
                  acceptReservation(reservation);
                "
                >Accept task</b-button
              ><b-button
                variant="success"
                v-if="
                  reservation.task.assignmentStatus == 'wrapping' ||
                    reservation.task.assignmentStatus == 'assigned'
                "
                :disabled="reservation.disableButtons"
                v-on:click="
                  reservation.disableButtons = true;
                  completeTask(reservation.task.sid);
                "
                >Complete task</b-button
              >
            </div>
          </div>
          <!--end::Info-->
        </div>
        <!--end::Item-->
      </template>
      <template v-if="reservations.length == 0">
        <!--begin::Item-->
        <div class="d-flex flex-wrap align-items-center mb-9">
          <!--begin::Title-->
          <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
            <h2>Caller: #</h2>
            <span class="text-muted font-weight-bold font-size-lg"
              >Language:
              <span class="text-primary font-weight-bold">#</span></span
            >
            <span class="text-muted font-weight-bold font-size-lg my-1">
              Reservation Status: #
            </span>
            <span class="text-muted font-weight-bold font-size-lg"
              >Task Status:
              <span class="text-primary font-weight-bold">#</span></span
            >
          </div>
          <!--end::Title-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "reservation-list",
  props: {
    reservations: Array
  },
  data() {
    return {};
  },
  methods: {
    lastElement(i) {
      return i !== this.reservations.length - 1;
    },
    acceptReservation(reservation) {
      this.$emit("reservationAccepted", reservation);
    },
    completeTask(taskSid) {
      this.$emit("taskCompleted", taskSid);
    }
  }
};
</script>
