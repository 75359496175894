var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b card-stretch"},[_vm._m(0),_c('div',{staticClass:"card-body pt-2"},[_vm._l((_vm.reservations),function(reservation,i){return [_c('div',{key:i,staticClass:"d-flex flex-wrap align-items-center",class:{ 'mb-9': _vm.lastElement(i) }},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3"},[(reservation.task.attributes.caller)?[_c('h2',[_vm._v("Caller: "+_vm._s(reservation.task.attributes.caller))]),_c('span',{staticClass:"text-muted font-weight-bold font-size-lg"},[_vm._v("Language: "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(_vm._f("language")(reservation.task.attributes.selected_language)))])])]:[_c('h2',[_vm._v("To: "+_vm._s(reservation.task.attributes.to))])],_c('span',{staticClass:"text-muted font-weight-bold font-size-lg my-1"},[_vm._v(" Reservation Status: "+_vm._s(reservation.reservationStatus)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-lg"},[_vm._v("Task Status: "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(reservation.task.assignmentStatus))])])],2),_c('div',{staticClass:"d-flex align-items-center py-lg-0 py-2"},[_c('div',{staticClass:"d-flex flex-column text-right"},[(reservation.reservationStatus == 'pending')?_c('b-button',{attrs:{"variant":"success","disabled":reservation.disableButtons},on:{"click":function($event){reservation.disableButtons = true;
                _vm.acceptReservation(reservation);}}},[_vm._v("Accept task")]):_vm._e(),(
                reservation.task.assignmentStatus == 'wrapping' ||
                  reservation.task.assignmentStatus == 'assigned'
              )?_c('b-button',{attrs:{"variant":"success","disabled":reservation.disableButtons},on:{"click":function($event){reservation.disableButtons = true;
                _vm.completeTask(reservation.task.sid);}}},[_vm._v("Complete task")]):_vm._e()],1)])])]}),(_vm.reservations.length == 0)?[_vm._m(1)]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v("Reservations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-wrap align-items-center mb-9"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3"},[_c('h2',[_vm._v("Caller: #")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-lg"},[_vm._v("Language: "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("#")])]),_c('span',{staticClass:"text-muted font-weight-bold font-size-lg my-1"},[_vm._v(" Reservation Status: # ")]),_c('span',{staticClass:"text-muted font-weight-bold font-size-lg"},[_vm._v("Task Status: "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("#")])])])])
}]

export { render, staticRenderFns }